import * as React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsletterBanner from "../components/newsletterBanner";

const query = graphql`
  query {
    allStrapiPage(limit: 4, sort: { fields: publication_date, order: DESC }) {
      nodes {
        id
        Titolo
        Permalink
        Copertina {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Chi siamo - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative sterea-blue-gradient">
        {/* <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div> */}
        <div className="sterea-center-column h-screen z-20 flex justify-center items-center">
          <h1 className="w-1/2 text-white text-center text-xl lg:text-5xl leading-normal font-bold">
            Chi siamo
          </h1>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min">
          <div className="sterea-content-container py-lg lg:w-full">
            <p>
              Sterea Medicina e Viaggi è un’azienda italiana, tutta al femminile,
              specializzata nel turismo medicale, che ha acquisito nel corso
              degli anni le competenze necessarie per facilitare il processo di
              consulenza e rimuovere le difficoltà nell'organizzazione di un
              viaggio medico.
            </p>
            <p>
              Non siamo dottori né tour operator, siamo consulenti e ci
              concentriamo sulle richieste e le esigenze dei nostri
              clienti/pazienti che desiderano ottenere un trattamento in un
              paese straniero. Offriamo servizi non medici, ci prendiamo cura
              della logistica, forniamo un rapido accesso ad ospedali e medici,
              pensiamo al ritiro e all’accompagnamento all'aeroporto,
              all’alloggio, ai servizi di traduzione, e al trasporto da e per
              appuntamenti medici.
            </p>
            <p>
              Con la nostra esperienza e la nostra organizzazione siamo pronti a
              seguirvi e guidarvi sin da subito, a partire dal primo contatto,
              fino alla degenza post operatoria nei mesi successivi
              all’intervento. Abbiamo scelto di collaborare con i più seri
              partner medici in tutta Europa e questo ci permette di garantire
              una qualità di servizio impeccabile.
            </p>
            <p>
              La soddisfazione dei nostri cliente sarà sempre la dimensione più
              importante nella nostra preparazione di pacchetti di turismo
              medico. La priorità è che vi sentiate sicuri e tranquilli, noi vi
              supporteremo sempre durante tutto il loro percorso.
            </p>
          </div>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column py-lg px-min flex flex-wrap">
          <div className="w-full lg:pl-6 flex flex-col items-center">
            <div className="w-1/2">
              <StaticImage
                quality={90}
                src="../images/laura_chi_siamo.png"
                placeholder="none"
                layout="constrained"
                alt="Foto di Laura Serzentaite, della Sterea Medicina e Viaggi"
              />
            </div>
            <h2 className="mt-12 mb-0">Laura Serzentaite</h2>
            <h3 className="mb-12">Titolare della UAB Stereja in Lituania</h3>
            <div className="w-full">
              <p>
                CEO della Uab Stereja, nostra partner in Lituania. Parla
                fluentemente italiano, inglese e russo. Laura è in continuo
                contatto con i referenti delle Strutture da noi selezionate, e
                coordina tutte le attività organizzative relative alle procedure
                e alla logistica dei nostri servizi in loco. Esperta
                in <span className="font-bold">Chirurgia Estetica</span> ed <span className="font-bold">Odontoiatria</span> sarà il vostro punto di
                riferimento per consigliarvi e chiarirvi le idee in questi
                ambiti.
              </p>
            </div>
          </div>
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
